import React, { Suspense }  from 'react';
import ReactDOM from 'react-dom';

import {Provider} from 'react-redux';

import store from './store';
const Wrapper = React.lazy(() => import('./wrapper'));

// import Wrapper from './wrapper'

ReactDOM.render(
	<Provider store={store}>
		<Suspense fallback={<div className="init-diamond" />}>
			<Wrapper />
		</Suspense>
	</Provider>
, document.getElementById('App'));
