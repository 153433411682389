import {createStore, combineReducers} from 'redux';


const defaultApp = {
	overlay: false, //ABOUT, CONTACT, CASE_STUDY
	transitioning: false,
	shadowbox: false,
}

const appSettings = function (_appSettings = defaultApp, action) {
	switch (action.type) {
		case 'SET_APP_MODE':
			return {
				..._appSettings,
				overlay: action.mode,
			};
			break;
		case 'SET_APP_TRANSITIONING':
			return {
				..._appSettings,
				transitioning: action.transitioning,
			};
			break;
		case 'SET_APP_SHADOWBOX':
			return {
				..._appSettings,
				shadowbox: action.shadowbox,
			};
			break;
		default:
			return _appSettings;
	}
}

const default_colours = {
	background: "#00141E",
	foreground: "#FF005E",
	highlight: "#F9F0E3",
}

const defaultPrefs = {
	changed: sessionStorage.getItem('background')  || sessionStorage.getItem('foreground') || sessionStorage.getItem('highlight') || false,
	colours_changed: 0,
	animated: true,
	perspective: 800,
	depth: 500,
	max_rotation: 15,
	colour_background: sessionStorage.getItem('background') || default_colours.background,
	colour_foreground: sessionStorage.getItem('foreground') || default_colours.foreground,
	colour_highlight: sessionStorage.getItem('highlight') || default_colours.highlight,

}

const prefSettings = function (_prefSettings = defaultPrefs, action) {

	let changed = sessionStorage.getItem('background')  || sessionStorage.getItem('foreground') || sessionStorage.getItem('highlight') || false;

	switch (action.type) {
		case 'SET_PREF_ANIMATED':
			changed = changed || (action.setting !== defaultPrefs.animated);
			return {
				..._prefSettings,
				animated: action.setting,
				changed: changed
			}
			break;
		case 'SET_PREF_PERSPECTIVE':
			changed = changed || (action.setting !== defaultPrefs.perspective);
			return {
				..._prefSettings,
				perspective: action.setting,
				changed: changed

			}
			break;
		case 'SET_PREF_DEPTH':
			changed = changed || (action.setting !== defaultPrefs.depth);
			return {
				..._prefSettings,
				depth: action.setting,
				changed: changed
			}
			break;
		case 'SET_PREF_ROTATION':
			changed = changed || (action.setting !== defaultPrefs.rotation);
			return {
				..._prefSettings,
				max_rotation: action.setting,
				changed: changed
			}
			break;
		case 'SET_PREF_COLOUR_FOREGROUND':
			changed = changed || (action.setting !== default_colours.foreground);
			sessionStorage.setItem('foreground', action.setting);
			return {
				..._prefSettings,
				colour_foreground: action.setting,
				changed: changed,
				colours_changed: Date.now()
			}
			break;
		case 'SET_PREF_COLOUR_BACKGROUND':
			changed = changed || (action.setting !== default_colours.background);
			sessionStorage.setItem('background', action.setting);

			return {
				..._prefSettings,
				colour_background: action.setting,
				changed: changed,
				colours_changed: Date.now()
			}
			break;
		case 'SET_PREF_COLOUR_HIGHLIGHT':
			changed = changed || (action.setting !== default_colours.highlight);
			sessionStorage.setItem('highlight', action.setting);
			return {
				..._prefSettings,
				colour_highlight: action.setting,
				changed: changed,
				colours_changed: Date.now()

			}
			break;
		case 'RESET_PREF':
			sessionStorage.clear();

			return {
				...defaultPrefs,
				colours_changed: Date.now(),
				colour_foreground: default_colours.foreground,
				colour_background: default_colours.background,
				colour_highlight: default_colours.highlight,
				changed: false

			}
			break;
		default:
			return _prefSettings;
	}
}

const defaultStack = {
	rotation: {
		x: 0,
		y: 0,
		perX: 0,
		perY: 0
	},
	enabled: false,
	transitioning: false,
	iteration: 0,
	style: "STACK", // STACK or LIST
}

const stackSettings = function (_stackSettings = defaultStack, action) {
	switch (action.type) {
		case 'SET_STACK_ROTATION':
			return {
				..._stackSettings,
				rotation: {
					x: action.x,
					y: action.y,
					perX: action.perX,
					perY: action.perY
				}
			}
		case 'RESET_STACK_ROTATION':
			return {
				..._stackSettings,
				rotation: {
					x: defaultStack.rotation.x,
					y: defaultStack.rotation.y
				}
			}
		case 'SET_STACK_ENABLED':
			return {
				..._stackSettings,
				enabled: action.setting
			}
		case 'SET_STACK_TRANSITIONING':
			return {
				..._stackSettings,
				transitioning: action.setting
			}
		case 'SET_STACK_ANIM_ITERATION':
			return {
				..._stackSettings,
				iteration: _stackSettings.iteration + action.setting
			}

		case 'SET_STACK_STYLE':
			return {
				..._stackSettings,
				style: action.style
			}
		default:
			return _stackSettings;
	}
}




const defaultInterface = {
	expand: true,
	message: false
};


const interfaceSettings = function (_interface = defaultInterface, action) {
	switch (action.type) {
		case 'SET_UI_EXPAND':
			return {..._interface, expand: action.setting};
			break;
		case 'SET_UI_MESSAGE':
			return {..._interface, message: action.message};
		default:
			return _interface;

	}
};


function setActiveProject(obj = {}, index = 0) {

	let p = obj[0];
	index = (index >= p.total) ? 0 : index;
	index = (index < 0) ? p.total - 1 : index;

	obj.forEach((project) => {
		project.current = index;
		if (project.index === index) {
			project.active = true;
		} else {
			project.active = false;
		}
	});
	return obj;
};

const projectManager = function(projects = [], action) {
	let _projects;
	switch (action.type) {
		case 'ADD_PROJECT':
			_projects = projects.concat([]);
			_projects[action.index] = action.project;
			return _projects;
			break;
		case 'SET_PROJECT_ACTIVE':
			_projects = projects.slice(0);
			return setActiveProject(_projects, action.activate);
			break;
		case 'SET_PROJECT_EXPAND':
			_projects = projects.slice(0);
			_projects.forEach((project) => {
				project.expanded = action.index;
				if (project.index === action.index) {
					project.expand = true;
				} else {
					project.expand = false;
				};
			});
			return _projects;
			break;

		case 'SET_PROJECT_PROGRESS':
			_projects = projects.slice(0);
			let current = _projects[0].current;
			return setActiveProject(_projects, current + action.direction);
		default:
			return projects;
	}
};

const listManager = function(projects = [], action) {

	let _projects;
	switch (action.type) {
		case 'ADD_SECONDARY':
			_projects = projects.concat([]);
			_projects[action.index] = action.project;
			return _projects;
			break;
		case 'SET_SECONDARY_EXPAND':
			_projects = projects.slice(0);
			_projects.forEach((project) => {
				project.expanded = action.index;
				if (project.index === action.index) {
					project.expand = true;
				} else {
					project.expand = false;
				};
			});
			return _projects;
			break;
		default:
			return projects;
	}
}


const defaultLoader = {
	total: 0,
	count: 0,
	loaded: false,
};

const loaderSettings = function (_loaderSettings = defaultLoader, action) {
	switch (action.type) {
		case 'INC_LOAD_TOTAL':
			return {
				..._loaderSettings,
				total: _loaderSettings.total + action.inc
			};
			break;
		case 'ITEM_LOADED':
			return {
				..._loaderSettings,
				count: _loaderSettings.count + action.inc
			};
			break;
		case 'SET_APP_LOADED':
			return {
				..._loaderSettings,
				loaded: action.bool
			};
			break;
		default:
			return _loaderSettings;
	}
};

const reducers = combineReducers({
	app: appSettings,
	preferences: prefSettings,
	projects: projectManager,
	list: listManager,
	stack: stackSettings,
	loader: loaderSettings,
	interface: interfaceSettings
});


let store = createStore(
	reducers,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
